
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { bus } from "@/main";
  import { Mandate } from "@/store/modules/mandate.store";

  @Component
  export default class NotaryInfo extends Vue {
    @Prop() notary!: { name: string; type: "BUYER" | "SELLER" };

    @Prop({ default: false }) activity!: Activity;

    @Prop({ default: false }) mandate!: Mandate;

    @Prop({ default: false }) canEdit!: boolean;

    @Prop({ default: false }) canReset!: boolean;

    @Prop({ default: false }) canDelete!: boolean;

    bus = bus;

    get subject() {
      if (!this.notary.type) {
        return false;
      }

      if (this.notary.type === "BUYER") {
        return this.activity;
      }

      if (this.notary.type === "SELLER") {
        return this.mandate;
      }
    }
  }
